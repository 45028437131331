import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';
import styled from 'styled-components';

import DefaultPageLayout from '../../components/DefaultPageLayout';

const Lead = styled.p`
  font-size: 2em;
  font-weight: 500;
  text-align: center;
`;

export default () => (
  <DefaultPageLayout title="NgRx Course: Thank you for contact us.">
    <Segment style={{ padding: '8em 0em' }} vertical>
      <Grid container stackable verticalAlign="middle">
        <Grid.Row centered>
          <Grid.Column width={16}>
            <Header as="h1">Hire Us</Header>
            <Lead>We can't wait to help you out! We'll be in touch soon.</Lead>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </DefaultPageLayout>
);
